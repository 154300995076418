import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';

@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.scss'],
})
export class EditImageComponent implements OnInit {
  @Input() imageForEdit: string | ArrayBuffer = ''; // Imagen recibida como Base64 o ArrayBuffer
  @Input() typeImage = ''; // Tipo de imagen (opcional)
  @Input() registerImagenLogin = true;
  @Input () insertWatermark = false;
  @Input() fromLogin = false; // ¿Viene del flujo de inicio de sesión?
  @Output() imageEdited = new EventEmitter<File>(); // Emite la imagen procesada
  @Output() cancelEditImage = new EventEmitter<void>(); // Cancela la operación

  imagePreview: string | null = null; // Vista previa de la imagen
  user: any = '';

  constructor(private authService: AuthServiceService) {}

  ngOnInit(): void {
    this.prepareImage(); // Procesar imagen al inicializar
    this.user = JSON.parse(this.authService.getLocalStorage('user'));
  }

    /**
     * Agrega una marca de agua a la imagen procesada.
     */
  async addWatermarkToCanvas(imageUrl: string, userName: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageUrl;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (!context) {
          reject('No se pudo obtener el contexto del lienzo.');
          return;
        }

        canvas.width = image.width;
        canvas.height = image.height;

        context.drawImage(image, 0, 0);

        // Tamaño y posición de la marca de agua
        const sizeRelation = Math.ceil(canvas.width / 15);
        const text = `lust-list.com/model/${userName}`;
        context.font = `${Math.ceil(sizeRelation / 2)}px Arial`;
        context.fillStyle = '#8d8d8d';
        context.textBaseline = 'bottom';

        // Medir texto y ajustar posición
        const textWidth = context.measureText(text).width;
        context.fillText(
          text,
          canvas.width - textWidth - 20,
          canvas.height - 20
        );

        const imageWithWatermark = canvas.toDataURL('image/jpeg');
        resolve(imageWithWatermark);
      };

      image.onerror = () => {
        reject('No se pudo cargar la imagen base.');
      };
    });
  }

  /**
   * Emite la imagen procesada con la marca de agua.
   */
  async uploadImage(): Promise<void> {
    if (this.imagePreview && typeof this.imageForEdit === 'string') {
      try {
        let processedImage: string;

        if (this.registerImagenLogin) {
          // Si registerImagenLogin es true, agrega la marca de agua
          processedImage = await this.addWatermarkToCanvas(
            this.imageForEdit,
            this.user.userName
          );
        } else {
          // Si registerImagenLogin es false, solo devuelve la imagen original
          processedImage = this.imageForEdit;
        }

        // Convertir base64 a Blob
        const imageBlob = this.base64ToBlob(processedImage, 'image/jpeg');
        const file = new File([imageBlob], 'image_with_watermark.jpeg', { type: 'image/jpeg' });

        const formData = new FormData();
        formData.append('file', file);

        // Emitir la imagen procesada
        this.imageEdited.emit(file);
      } catch (error) {
        console.error('Error al procesar la imagen:', error);
      }
    } else {
      console.error('No hay imagen para procesar o el formato no es compatible.');
    }
  }

  /**
   * Cancela la operación de carga de imagen.
   */
  cancelEdit(): void {
    this.imagePreview = null;
    this.cancelEditImage.emit();
  }
  /**
   * Procesa la imagen recibida para generar una vista previa.
   */
  prepareImage(): void {
    if (this.imageForEdit) {
      if (typeof this.imageForEdit === 'string') {
        this.imagePreview = this.imageForEdit;
      } else if (this.imageForEdit instanceof ArrayBuffer) {
        const blob = new Blob([this.imageForEdit]);
        this.imagePreview = URL.createObjectURL(blob);
      } else {
        console.error('Formato de imagen no compatible.');
      }
    } else {
      console.error('No se recibió ninguna imagen para editar.');
    }
  }

  /**
   * Convierte una cadena base64 a un Blob.
   */
  private base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(0)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }


}
