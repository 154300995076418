import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LayoutService } from '../../services/layout/layout.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
})
export class WarningComponent implements OnInit {
  @Input() showButton = false;
  @Output() closeModalEvent = new EventEmitter();

  redirect: boolean | any = false;
  subReditect: Subscription;
  constructor(
    private layoutService: LayoutService,
      private router: Router,
  ) { }

  ngOnInit() {
    this.subReditect = this.layoutService.getRedirectModalWarning().subscribe((val) => {
      this.redirect = val;
    });
  }

  back() {
    if (!this.redirect) {
    this.router.navigate(['lust-list']);
    }
    this.layoutService.setShowModalWarning(false);
  }

  closeModal() {
this.closeModalEvent.emit();
  }

}
