import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/users/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { MenuController } from '@ionic/angular';
import { LayoutService } from '../../services/layout/layout.service';

@Component({
  selector: 'app-header-navigation-bar',
  templateUrl: './header-navigation-bar.component.html',
  styleUrls: ['./header-navigation-bar.component.scss'],
})
export class HeaderNavigationBarComponent implements OnInit {
   amount1: string;
  amount2: string;
  timeOutId: NodeJS.Timeout;
  urlNavitate: string;
  currentRoute: string;
  user: any;
  constructor(
    private usersService: UsersService,
    private router: Router,
    private authService: AuthServiceService,
    private menu: MenuController,
    private  layoutService: LayoutService
  ) { }

  ngOnInit() {
    const userData = JSON.parse(this.authService.getLocalStorage('user'));
    this.user = userData;
    this.getSummary();
      this.currentRoute = window.location.pathname;
   }

  openLeft() {
    this.menu.open('menu');
  }

getSummary() {
  clearTimeout(this.timeOutId);

  this.usersService.getSummary().subscribe((res: any) => {
    const amount = res.amount || 0;
    this.balanceTransform(amount);

    this.timeOutId = setTimeout(() => {
      this.getSummary();
    }, 300000);
  });
}

  navigateTo(url: string) {
    this.router.navigate([`/${url}`]);
  }

  layout() {
      //CAMBIO CUANDO SE ACOMODE LOS WISH P2P
    this.layoutService.setShowModalWarning(true);

    this.layoutService.setRedirectModalWarning(false);
  }

balanceTransform(coin: number) {
  let formattedAmount: string;
  const decimalPart = (coin % 1).toFixed(2).split('.')[1] || '00';
  const integerPart = Math.floor(coin);

  if (integerPart >= 1000000) {
    formattedAmount = (integerPart / 1000000).toFixed(1) + 'm';
  } else if (integerPart >= 1000) {
    formattedAmount = (integerPart / 1000).toFixed(1) + 'k';
  } else {
    formattedAmount = integerPart.toString();
  }
  this.amount1 = formattedAmount;
  this.amount2 = decimalPart;
}



}
