import { Component, OnDestroy, OnInit,ViewChild,ElementRef} from '@angular/core';
import { LayoutService } from '../../services/layout/layout.service';
import { Subscription } from 'rxjs';
import { MoviesService } from '../../services/movies/movies.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { AccountsService } from '../../services/accounts/accounts.service';
import { ToastService } from '../../services/toast/toast.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-modal-maxdonwloads-movie-paytment',
  templateUrl: './modal-maxdonwloads-movie-paytment.component.html',
  styleUrls: ['./modal-maxdonwloads-movie-paytment.component.scss'],
})
export class ModalMaxdonwloadsMoviePaytmentComponent implements OnInit, OnDestroy {
 @ViewChild('buttonDownload') buttonDownload: ElementRef;
  isOpen = false;
  dataMovie: any;
  subsData: Subscription;
  myId: any;
  myFunds = 0;

  constructor(
    private layoutService: LayoutService,
    private moviesService: MoviesService,
    private authService: AuthServiceService,
    private accountsService: AccountsService,
    private toastService: ToastService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.myId = this.authService.getLocalStorage('userId');
    this.accountsService.getMyAccount().subscribe(
      (res: any) => this.myFunds = res.amount,

      (error) => console.error(error)
    );
    this.subsData = this.layoutService.getDataModalMaxDownloadPay().subscribe((val: any) => {
      this.dataMovie = val;
    });
  }
  ngOnDestroy(): void {
    if (this.subsData) {
       this.subsData.unsubscribe();
    }
  }
  cancel() {
  this.layoutService.setShowModalMaxDownloadPay(false);
  }

  externalPaymentModel() {

  }

downloadFile(videoUrl: string, movie): void {
  const url = videoUrl;
  let payload: any;
  const startTime = Date.now(); // Registrar el tiempo de inicio
   payload = {
      open: true,
      name: movie.title,
      size: Math.round(movie.video.sizeMb)
   };

    this.layoutService.setDataNotificationDownloadMovie(payload);
  this.http
    .get(url, { responseType: 'blob' })
    .subscribe({
      next: (response) => {
        const endTime = Date.now(); // Registrar el tiempo de finalización
        const elapsedTime = (endTime - startTime) / 1000; // Convertir a segundos
    payload = {
      open: false,
      name: movie.title,
      size: Math.round(movie.video.sizeMb)
   };
    this.layoutService.setDataNotificationDownloadMovie(payload);
        const blob = new Blob([response], { type: 'video/mp4' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'video.mp4'; // Opcional: asignar un nombre al archivo descargado
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      error: (err) => {
        console.error('Error al descargar el archivo:', err);
      },
    });
}

  downloadVideo(movie) {
    this.moviesService.addDownloadsMovie(movie._id, this.myId).subscribe((data: any) => {
    this.downloadFile(movie.video.videoUrl, movie);

    });
  }
  onDownload($event) {
    const payLoad = {
      open: false
    };
    this.layoutService.setDataNotificationDownloadMovie(payLoad);
    this.toastService.presentToast('TOAST.DOWNLOAD_VIDEO_FINISH', 2500);
  }

  errorDownload($event) {
    const payLoad = {
      open: false
    };
    this.layoutService.setDataNotificationDownloadMovie(payLoad);
    this.toastService.presentToast('TOAST.DOWNLOAD_VIDEO_ERROR', 2500);
  }
  confirm() {
      this.moviesService.addNewDownloadPay(this.myId, this.dataMovie.transferFee).subscribe((res) => {
      this.buttonDownload.nativeElement.click();
        this.cancel();
        this.toastService.presentToast('GENERAL.YOU_VIDEO_IS_DOWNLOADING', 3500);
      });
  }
}
