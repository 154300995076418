/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnDestroy, OnInit, HostListener, Renderer2, ViewChild, ElementRef} from '@angular/core';
import {  NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { LoadingController, MenuController} from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthServiceService } from './features/auth/service/auth-service.service';
import {
  DataComponent,
  LayoutService,
} from './shared/services/layout/layout.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { UsersService } from './shared/services/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { AdsService } from './shared/services/ads/ads.service';
import { environment } from 'src/environments/environment';
import { SocketService } from './shared/services/socket/socket.service';
import { HandleInactivityService } from './shared/services/handeInactivity/handle-inactivity.service';
import { SwUpdate } from '@angular/service-worker';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  env = environment;
  @ViewChild('audioPlayerNotification') audioPlayerRef: ElementRef;


  isAuthenticated = false;
  user: any = {};
  allLust = [];
  subscription: Subscription;
  componentSelect: DataComponent;
  newPubSub: Subscription;
  showNewPub: boolean | any = false;
  newStateSub: Subscription;
  stateModalSub: Subscription;
  showNewState: boolean | any = false;
  statesSelected: boolean | any = false;
  showModalPaymentSubPlataform: boolean | any = false;
  showModalMaxDonwloadsPay: boolean | any = false;
  showModalOnline: boolean | any = false;

  subsModalMaxDonwloadPay: Subscription;
  newWishSub: Subscription;
  modalFrame: Subscription;
  modalMovieFrameDetail: Subscription;
  modalCommnets: Subscription;
  newOfferSub: Subscription;
  newAttributesSub: Subscription;
  newServiceModalSub: Subscription;
  mettingSelectSub: Subscription;
  userSub: Subscription;
  modalBillingDetail: Subscription;
  dataBillingDetailSub: Subscription;
  subModalPaymentSubPlataform: Subscription;
  subModalClientInfo: Subscription;
  billingDetialId: any = null;
  showModalBillingDetail: boolean | any = false;
  showWish: boolean | any = false;
  showOffer: boolean | any = false;
  showModalClientInfo: boolean | any = false;
  showModalFrame: boolean | any = false;
  openComments: boolean | any = false;
  showAttributes: boolean | any = false;
  showServiceModal: boolean | any = false;
  showNewPublication: boolean | any = false;
  showModalNewVideos: boolean | any = false;
  showModalInformation: boolean | any = false;
  showModalPaymentMethod: boolean | any = false;
  showModalMovieFrameDetail: boolean | any = false;
  billTickeDetail: boolean | any = false;
  showModalSubsFollow: boolean | any = false;
  showModalBlockAds: boolean | any = false;
  showModalBlockPop: boolean | any = false;
  subModalBlockPop: Subscription;
  subShowModalOnline: Subscription;


  dataWish: any = null;
  dataMovieWish: any = null;

  subsOfModalSubFollow: Subscription;
  modalSubBillTicketDetail: Subscription;
  modalSubPaymentMethod: Subscription;
  modalSubsInformation: Subscription;
  modalSubsWishDetail: Subscription;
  showSuBModalWishDetail: Subscription;
  subModalAddVideos: Subscription;
  subModalBlockAds: Subscription;
  subModalMetrics: Subscription;
  subModalRedSocial: Subscription;
  dataWishDetail: any = null;
  showModalWishDetail: boolean | any = false;
  myId = '';
  pageLoaded = false;
  modalAttributesType: string;
  mettingSelect: any = {};
  firtsUrl = '';
  priceSubPlataform = null;
  subModalWarning: Subscription;
  showModalWarning: boolean | any = false;


  showModalRedSocial: boolean | any = false;


  minutesForMeeting = '';
  timer: any;

  languageDefault = 'en';

  modalMetrics = false;

  subscriptionSubscriptionPlatform: Subscription;

  subscriptionPlatform = false;

  idSetInterval: any;

  showAd = false;

  addForView = 4;

  showVideoVast = false;


  isPreview = false;
  initSession;
  private routeSubscription: Subscription;



  constructor(
    public authService: AuthServiceService,
    private router: Router,
    private layoutService: LayoutService,
    private loadingCtrl: LoadingController,
    private usersService: UsersService,
    private translate: TranslateService,
    private adService: AdsService,
    private renderer: Renderer2,
    private socketService: SocketService,
    private inactiveUser: HandleInactivityService,
    private menu: MenuController,
    private route: ActivatedRoute,
    private userService: UsersService,
    private swUpdate: SwUpdate
  ) {

    this.setAppLanguage();
    this.authService.isDeviceIOS = /iPhone|iPad/i.test(navigator.userAgent) && window.outerWidth < 1020;

  }
ngOnInit(): void {
  // Llamar al método de limpieza de caché

  if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('¡New Version! ¿Loading?')) {
          this.swUpdate.activateUpdate().then(() => window.location.reload());
        }
      });
    }
  this.clearCache();


  // Obtener precio de suscripción
  this.userService.getPriceSubByPlataformLustList().subscribe((res) => {
    this.priceSubPlataform = res[0].amount;
  });

  // Manejo de inactividad
  this.inactiveUser.userInactive.subscribe(() => {
    this.handleInactivity();
  });

  // Guardar la primera URL
  this.firtsUrl = window.location.pathname;

  // Verificar autenticación
  this.isAuth();

  // Inicializar la app
  this.initApp();

  // Lógica para manejar el modo de vista previa
  if (this.isPreview) {
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        if (!url.includes('profile-preview') && !url.includes('preview')) {
          this.routeSubscription.unsubscribe();
          this.isPreview = false;
        }
      }
    });
  }
}

//  clearCache(): void {
//   const storedVersion = localStorage.getItem('appVersion'); // Versión almacenada
//   const currentVersion = 'v1.1.9.1'; // Versión actual, puedes traerla dinámicamente si es necesario

//   if (storedVersion !== currentVersion) {
//     localStorage.setItem('appVersion', currentVersion);

//     if ('caches' in window) {
//       caches.keys().then((cacheNames) => {
//         cacheNames.forEach((cacheName) => {
//           caches.delete(cacheName);
//         });
//         window.location.reload();
//       });
//     } else {
//    (window as Window).location.reload();
//     }
//   }
//  }

  clearCache(): void {
  const storedVersion = localStorage.getItem('appVersion');
  const currentVersion = 'v1.1.9.4'; // Asegúrate de que esta versión esté actualizada

  if (storedVersion !== currentVersion) {
    localStorage.setItem('appVersion', currentVersion);

    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)))
          .then(() => {
            window.location.reload(); // true fuerza la recarga desde el servidor
          });
      });
    } else {
    (window as Window).location.reload();
    }
  }
}




  private handleInactivity() {
    this.closeSession();
  }
   /**
    * Cerrar sesión
    */
  closeSession() {
    this.usersService.deactivateUserActiveById().subscribe(res => {
      this.closeMenu();
      this.authService.closeSession();
      this.authService.pageLoaded = false;
      setTimeout(() => {
        this.authService.pageLoaded = true;
      }, 3800);
    }, err => console.log(err));
  }

   /**
    * Cerrar menu lateral
    */
  closeMenu() {
    this.menu.close('menu');
  }

addAdScript() {
  const script1 = document.querySelector('script[src="https://sillinesswoollen.com/9e/ac/80/9eac80c6cf8ba7c1cec6f40bfc204a90.js"]');
  const script2 = document.querySelector('script[src="//sillinesswoollen.com/6c/6e/10/6c6e10878ca2c123fdc5db9fec00c64f.js"]');

  if (!script1) {
    const adScript1 = this.renderer.createElement('script');
    adScript1.type = 'text/javascript';
    adScript1.src = 'https://sillinesswoollen.com/9e/ac/80/9eac80c6cf8ba7c1cec6f40bfc204a90.js';
    this.renderer.appendChild(document.body, adScript1);
  }

  if (!script2) {
    const adScript2 = this.renderer.createElement('script');
    adScript2.type = 'text/javascript';
    adScript2.src = '//sillinesswoollen.com/6c/6e/10/6c6e10878ca2c123fdc5db9fec00c64f.js';
    this.renderer.appendChild(document.body, adScript2);
  }
}




  removeAdScript() {
  const adScript = document.querySelector('script[src="//sillinesswoollen.com/9e/ac/80/9eac80c6cf8ba7c1cec6f40bfc204a90.js"]');
  if (adScript) {
    this.renderer.removeChild(document.body, adScript);
  }
}
//aqui estoy

getSubcriptionPlataform() {
  if (this.isAuthenticated) {
    let subscriptionPlatform;
    this.usersService.getSubscriptionPlataformUser().subscribe((data: any) => {
      subscriptionPlatform = data?.status?.name;

      if (subscriptionPlatform === 'cancelled' || subscriptionPlatform === 'finish' || !subscriptionPlatform) {
        setTimeout(() => {
          this.starIntervalByAds();
          this.addAdScript();
        }, 120000);
      }

      if (subscriptionPlatform === 'active') {
        this.removeVideoJs('4.7.1');
        this.addVideoJs('8.6.1');
        this.showAd = false;
        this.authService.removeLocalStorage('showAd');
        this.stopIntervalByAds();
        this.removeAdScript();
      }
    });
  }
}



  private addVideoJs(version: string) {
    const script = this.renderer.createElement('script');
    script.src = `https://vjs.zencdn.net/${version}/video.js`;
    this.renderer.appendChild(document.head, script);

    const style = this.renderer.createElement('link');
    this.renderer.setAttribute(style, 'href', `https://vjs.zencdn.net/${version}/video-js.css`);
    this.renderer.setAttribute(style, 'rel', 'stylesheet');
    this.renderer.appendChild(document.head, style);
  }

  private removeVideoJs(version: string) {
    const existingScripts = document.head.querySelectorAll(`script[src*="vjs.zencdn.net/${version}"]`);
    existingScripts.forEach((script) => this.renderer.removeChild(document.head, script));

    const existingStyles = document.head.querySelectorAll(`link[href*="vjs.zencdn.net/${version}"]`);
    existingStyles.forEach((style) => this.renderer.removeChild(document.head, style));
  }

  openModalSubPlataform() {
    if (this.getUserType() === '1') {

      const data = {
        type: 'model',
        paySub: true
      };
      this.layoutService.setDataModalPaymentSubsPlataform(data);
    }

    if (this.getUserType() === '2') {

      const data = {
        type: 'client',
        paySub: true
      };
      this.layoutService.setDataModalPaymentSubsPlataform(data);
    }
    this.layoutService.setShowModalPaymentSubsPlataform(true);
  }

  stopIntervalByAds() {
    if (this.idSetInterval) {
      this.authService.removeLocalStorage('showAd');
      clearInterval(this.idSetInterval);
    }
  }

  starIntervalByAds() {
    if (this.isAuthenticated){

  if (this.authService.getLocalStorage('showAd')) {
    this.showVideoVast = true;
    this.showAd = true;
    return;
  }

  if (this.idSetInterval) {
    clearInterval(this.idSetInterval);
  }

  // Crea un nuevo intervalo
  this.idSetInterval = setInterval(() => {
    this.showVideoVast = true;
    this.showAd = true;
    this.stopIntervalByAds();
    this.authService.insertLocalStorage('showAd', 'true');
  }, 600000);
  }
}


  closeModalAds() {
  this.showAd = false;
}

  getAd() {
    if (this.addForView > 1) {
      this.adService.getPublicationExt().subscribe((res: any) => {
        const data = this.parseXML(res);
        this.addForView--;

        const popup = window.open(data.items[0].direct_url, '_blank');
        if (!popup || popup.closed || typeof popup.closed === 'undefined') {
          this.layoutService.setShowModalBlockPop(true);
        }
      });
    } else {
      this.showVideoVast = true;
      this.addForView--;
    }
  }

 parseXML(xmlString: string) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
  const items = Array.from(xmlDoc.getElementsByTagName('item')).map(item => ({
    id: item.getElementsByTagName('id')[0].textContent,
    title: item.getElementsByTagName('title')[0].textContent,
    alias: item.getElementsByTagName('alias')[0].textContent,
    direct_url: item.getElementsByTagName('direct_url')[0]?.textContent || null
  }));

  return {
    items,
    itemCount: xmlDoc.getElementsByTagName('itemCount')[0].textContent
  };
}

  clickSkipVideo() {
    this.showVideoVast = false;
    this.authService.removeLocalStorage('showAd');
    this.starIntervalByAds();
  }


  // eslint-disable-next-line @typescript-eslint/member-ordering
  initApp() {

    this.closeApp();
    this.usersService.getUsersActiveServer();
    this.loadPage();
    this.layoutService.getComponent().subscribe((component: DataComponent) => {

      this.componentSelect = component;
    });
    this.newPubSub = this.layoutService
      .getShowComponentNewPublication()
      .subscribe((val) => {
        this.showNewPub = val;
      });


    this.subModalClientInfo = this.layoutService.getShowModalClientInfo().subscribe((val: any) => {
      this.showModalClientInfo = val;
    });

    this.subModalPaymentSubPlataform = this.layoutService.getShowModalPaymentSubsPlataform().subscribe((val) => {
      this.showModalPaymentSubPlataform = val;
    });
    this.subModalRedSocial = this.layoutService.getshowModalRedSocial().subscribe((val) => {
      this.showModalRedSocial = val;
    });

    this.subsModalMaxDonwloadPay = this.layoutService.getShowModalMaxDownloadPay().subscribe((val) => {
      this.showModalMaxDonwloadsPay = val;
    });

    this.subModalBlockAds = this.layoutService.getShowModalBlockAds().subscribe((val: any) => {

      this.showModalBlockAds = val;
    });
    this.subModalWarning = this.layoutService.getShowModalWarning().subscribe((val) => {
      this.showModalWarning = val;
    });

    this.subModalBlockPop = this.layoutService.getShowModalBlockPop().subscribe((val: any) => {

      this.showModalBlockPop = val;
    });

    this.modalMovieFrameDetail = this.layoutService.getShowModalMovieFrameDetail().subscribe((val: any )=> {
this.showModalMovieFrameDetail = val;
    });
    this.modalSubBillTicketDetail = this.layoutService
      .getShowModalBillTicketDetail().subscribe((val) => {
        this.billTickeDetail = val;
      });

    this.subModalMetrics = this.layoutService.getShowModalMetrics().subscribe((val: any) => {
      this.modalMetrics = val;
    });
    this.newStateSub = this.layoutService
      .getShowComponentNewState()
      .subscribe((val) => {
        this.showNewState = val;
      });

    this.modalSubPaymentMethod = this.layoutService.
      getShowModalPaymentMethods()
      .subscribe((val) => {
        this.showModalPaymentMethod = val;
      });

    this.subsOfModalSubFollow = this.layoutService
      .getShowModalSubsFollow().subscribe((val: any) => {
        this.showModalSubsFollow = val;
      });
    this.subShowModalOnline = this.layoutService
      .getShowModalOnline().subscribe((val: any) => {
        this.showModalOnline = val;
      });

    this.stateModalSub = this.layoutService
      .getShowStateModal()
      .subscribe((val) => {
        this.statesSelected = val;
      });

    this.modalBillingDetail = this.layoutService
      .getShowModalBillingDetail()
      .subscribe((val) => {
        this.showModalBillingDetail = val;
      });

    this.subModalAddVideos = this.layoutService
      .getDataShowModalAddNewVideos()
      .subscribe((val) => {
        this.showModalNewVideos = val;
      });

    this.dataBillingDetailSub = this.layoutService
      .getDataBillingDetail()
      .subscribe((val) => {
        this.billingDetialId = val;
      });

    this.modalSubsWishDetail = this.layoutService
      .getDataWishDetailModal()
      .subscribe((val) => {
        this.dataWishDetail = val;
      });
    this.showSuBModalWishDetail = this.layoutService
      .getShowModalWishDetail()
      .subscribe((val) => {
        this.showModalWishDetail = val;
    });


    this.modalCommnets = this.layoutService
      .getShowModalCommentFrame()
      .subscribe((val) => {
        this.openComments = val;
      });




      this.newWishSub = this.layoutService
      .getShowComponentNewWish()
      .subscribe((val) => {
        this.showWish = val;
      });

    this.modalFrame = this.layoutService
      .getShowModalFrameDetail()
      .subscribe((val) => {
        this.showModalFrame = val;
      });

    this.newAttributesSub = this.layoutService
      .getShowComponentAttributes()
      .subscribe((val) => {
        this.showAttributes = val.show;
        this.modalAttributesType = val.type;
      });
    this.newServiceModalSub = this.layoutService
      .getShowComponentServiceModal()
      .subscribe((val) => {
        if (!val?.reload) {
          this.showServiceModal = val;
        }
      });
    this.newOfferSub = this.layoutService
      .getShowNewOffer()
      .subscribe((val) => {
        this.showOffer = val;
      });
    this.modalSubsInformation = this.layoutService
      .getShowModalInfomation()
      .subscribe((val) => {
        this.showModalInformation = val;
      });

    // this.layoutService
    //   .getDataWishComponentNewPublication()
    //   .subscribe((data: any) => {
    //     const dataSavePublicationSave = JSON.parse(this.authService.getLocalStorage('publicationForWish'));
    //     if (data) {
    //       if (data?.ownerId && data?.wishId) {
    //         const dataPublication = {
    //           ownerId: data.ownerId,
    //           wishId: data.wishId
    //         };
    //         this.authService.insertLocalStorage('publicationForWish', JSON.stringify(dataPublication));
    //       }
    //     } else {
    //       if (dataSavePublicationSave) {
    //         this.router.navigateByUrl('/wish-list');
    //         const component: DataComponent = { component: 'wish-detail', contextId: dataSavePublicationSave.wishId };
    //         this.layoutService.setComponent(component);

    //         data = dataSavePublicationSave;
    //         this.layoutService.setShowComponentNewPublication(true);
    //       }
    //     }
    //     this.dataWish = data;
    //   });

    this.layoutService
      .getDataWishComponentNewMovie()
      .subscribe((data: any) => {
        const dataSaveMovieSave = JSON.parse(this.authService.getLocalStorage('movieForWish'));
        if (data) {
          if (data?.ownerId && data?.wishId) {
            const dataMovie = {
              ownerId: data.ownerId,
              wishId: data.wishId
            };
            this.authService.insertLocalStorage('movieForWish', JSON.stringify(dataMovie));
          }
        } else {
          if (dataSaveMovieSave) {
            this.router.navigateByUrl('/wish-list');

            data = dataSaveMovieSave;
            this.layoutService.setDataShowModalAddNewVideos(true);
          }
        }
        this.dataMovieWish = data;
      });

    this.mettingSelectSub = this.layoutService.getMeetingSelect().subscribe(
      (meet) => {
        this.mettingSelect = meet;

        const diffMilliseconds = new Date(this.mettingSelect?.start).getTime() - new Date().getTime();
        const minutes = Math.round(diffMilliseconds / 60000);
        this.minutesForMeeting = minutes + ':00 min';
        if (minutes > 0) {
          this.reverseCounter();
        } else {
          this.minutesForMeeting = 'now';
        }

      }
    );
  }

  getInformationUser() {
    this.usersService.getMyProfile().subscribe(
      (res: any) => {
       if (!res.dataUser?.imageProfile?.imageUrl) {
          this.router.navigateByUrl('/settings');
        }
        this.myId = res.dataUser._id;
        if (!res.dataUser.nickName) {
          this.createNickName();
        }
        this.usersService.getSellersFollowing();
        this.usersService.getMySubscriptions();
        this.usersService.getMyChatSubscriptions();
      },
      (error) => {
        console.error(error);
      }
    );
  }


  createNickName() {
    this.userService.nickName().subscribe();
  }

      /**
       * Coloca el idioma de la app
       */
  setAppLanguage(){
    let language = localStorage.getItem('language');
    if (!language) {
      language = this.getLanguageFromNavigator();
    }
    this.languageDefault = language;
    //localStorage.setItem('language', language);
    this.translate.setDefaultLang(this.languageDefault);
  }

  getLanguageFromNavigator() {
    const languagesAvailable = ['en', 'es'];
    const langNav = window.navigator.language;
    let language = languagesAvailable.find((langAvailable) =>
      langNav.includes(langAvailable)
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    language ? '' : (language = 'en');
    return language;
  }


  validateIsPreview(isAuth) {
    if (this.firtsUrl.includes('profile-preview') ) {
      if (isAuth === true) {
        const userId = this.firtsUrl.split('/')[2];
        this.router.navigate(['/profile', userId]);
      } else {
        this.isPreview = true;
        this.stopIntervalByAds();
      }
    }
    if (this.firtsUrl.includes('preview')) {
      this.isPreview = true;
      this.stopIntervalByAds();
    }
  }


  reverseCounter() {
    const timeString = this.minutesForMeeting;
    const [time, unit] = timeString.split(':');

    const textMin = ' ' + unit.split(' ')[1];

    let seconds = parseInt(time) * 60 + parseInt(unit.split(' ')[0]);

    this.timer = setInterval(() => {
      seconds--;

      if (seconds === 0) {
        this.stopTimer();
        this.minutesForMeeting = 'now';
        return;
      }

      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      this.minutesForMeeting = `${minutes}:${remainingSeconds.toString().padStart(2, '0')}${textMin}`;

    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  closeApp() {
    if (this.newPubSub) {
      if (this.newPubSub) {
        this.newPubSub.unsubscribe();
      }
      if (this.subModalBlockAds) {
        this.subModalBlockAds.unsubscribe();
      }
      if (this.subModalMetrics) {
        this.subModalMetrics.unsubscribe();
      }
      if (this.subsModalMaxDonwloadPay) {
        this.subsModalMaxDonwloadPay.unsubscribe();
      }
      if (this.subModalPaymentSubPlataform) {
        this.subModalPaymentSubPlataform.unsubscribe();
      }
      if (this.subModalClientInfo) {
        this.subModalClientInfo.unsubscribe();
      }
      if (this.subModalWarning) {
        this.subModalWarning.unsubscribe();
      }
      if (this.modalSubPaymentMethod) {
      this.modalSubPaymentMethod.unsubscribe();
      }
      if (this.modalMovieFrameDetail) {
        this.modalMovieFrameDetail.unsubscribe();
      }
      if (this.modalSubBillTicketDetail) {
        this.modalSubBillTicketDetail.unsubscribe();
      }
      if (this.subModalBlockPop) {
        this.subModalBlockPop.unsubscribe();
      }

      if (this.subsOfModalSubFollow) {
        this.subsOfModalSubFollow.unsubscribe();
      }
      if (this.newWishSub) {
        this.newWishSub.unsubscribe();
      }
      if (this.newOfferSub) {
        this.newOfferSub.unsubscribe();
      }
      if (this.newAttributesSub) {
        this.newAttributesSub.unsubscribe();
      }
      if (this.subscriptionSubscriptionPlatform) {
      this.subscriptionSubscriptionPlatform.unsubscribe();
      }

      if (this.dataBillingDetailSub) {
        this.dataBillingDetailSub.unsubscribe();
      }
      if (this.modalBillingDetail) {
        this.modalBillingDetail.unsubscribe();
      }
      if (this.newServiceModalSub) {
        this.newServiceModalSub.unsubscribe();
      }
      if (this.newStateSub) {
        this.newStateSub.unsubscribe();
      }
      if (this.subModalAddVideos) {
        this.subModalAddVideos.unsubscribe();
      }
      if (this.modalSubsWishDetail) {
        this.modalSubsWishDetail.unsubscribe();
      }
      if (this.showSuBModalWishDetail) {
        this.showSuBModalWishDetail.unsubscribe();
      }

      if (this.stateModalSub) {
         this.stateModalSub.unsubscribe();
     }
      if (this.mettingSelectSub) {
         this.mettingSelectSub.unsubscribe();
      }
      if (this.modalFrame) {
        this.modalFrame.unsubscribe();
      }

      if (this.modalSubsInformation) {
        this.modalSubsInformation.unsubscribe();
      }

      if (this.layoutService) {
        this.layoutService.cleanMeetingIdTimeOut();
      }

    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if (this.subscriptionSubscriptionPlatform) {
      this.subscriptionSubscriptionPlatform.unsubscribe();
      this.stopIntervalByAds();
    }
  }

  ngOnDestroy(): void {
    this.closeApp();
  }

  ionViewWillLeave() {
    this.closeApp();
  }

  /**
   * Muestra el logo de carga
   */
  async loadPage() {
    if (!this.authService.pageLoaded) {
      const loading = await this.loadingCtrl.create({
        spinner: null,
        duration: 1000,
        cssClass: 'custom-loading',
      });
      setTimeout(async () => {
        //await loading.present();
        (this.authService.pageLoaded = true);
      }, 3800);
    }
  }

  isAuth() {
    this.subscription = this.authService.auth$.subscribe((res) => {

      if (window.location.href.includes('/auth/login/add-data-profile/')) {
        this.isAuthenticated = false;
        return;
      }

      this.isAuthenticated = res;
      if (res) {
        this.layoutService.setUpdateStatus();
        this.getInformationUser();
        this.initApp();

        this.getSubcriptionPlataform();
      } else {
        if (this.subscriptionSubscriptionPlatform) {
          this.subscriptionSubscriptionPlatform.unsubscribe();
          this.stopIntervalByAds();
        }
      }
    });
    const isAuth = this.authService.getLocalStorage('user') ? true : false;
    this.validateIsPreview(isAuth);
    this.getInformationUser();
  }
  getUserType() {
    return this.authService.getLocalStorage('userType');
  }

  calculateTimeEfectiveOfMeeting(scheduled) {

    const bonusTime = 10;
    let minutes = 10;
    if (scheduled.end) {
      const diffMilliseconds = new Date(scheduled?.end).getTime() - new Date(scheduled?.start).getTime();
      minutes = scheduled.effectiveTime ? scheduled.effectiveTime : Math.round(diffMilliseconds / 60000) - bonusTime;
    }

    return minutes + 'min';
  }

}
