import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MoviesService } from '../../services/movies/movies.service';
import { ToastService } from '../../services/toast/toast.service';
import { PublicationService } from '../../services/publication/publication.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController } from '@ionic/angular';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-uploap-large-video-cd',
  templateUrl: './uploap-large-video-cd.component.html',
  styleUrls: ['./uploap-large-video-cd.component.scss'],
})
export class UploapLargeVideoCdComponent implements OnInit {


  @ViewChild('inputGallery') inputGallery: ElementRef;
  @ViewChild('recordedVideo') recordVideoElementRef: ElementRef;
  @Output() videoPreview = new EventEmitter<any>();
  @Output() movieSaved = new EventEmitter<any>();
  recordVideoElement: any;
  isPlayVideo = false;
  videoTemp: File;
  sectionsToUpload = 12;
  loading: HTMLIonLoadingElement;
  uploadVideoButton = true;


  env = environment;
  uploadView = false;

  progressValue = 0;
  isShortVideo = false;
// eslint-disable-next-line @typescript-eslint/naming-convention
  HOSTNAME = 'storage.bunnycdn.com';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  STORAGE_ZONE_NAME = 'lustlist'; // Nombre de tu zona de almacenamiento
  // eslint-disable-next-line @typescript-eslint/naming-convention
 ACCESS_KEY = 'b6af03fc-e031-444c-be105b748d72-9ba5-438a'; // Clave API completa




  constructor(
    private toastService: ToastService,
    private moviesService: MoviesService,
    private publicationService: PublicationService,
    private translateService: TranslateService,
    private loadingCtrl: LoadingController

  ) { }

  ngOnInit() {}

processFile(): void {
    this.showLoading();
    this.uploadVideoButton = false;
    if (!this.videoTemp) {
      console.error('No hay archivo seleccionado para subir.');
      return;
    }

    // Generar un ID único para el archivo
    const uniqueId = uuidv4();
  const fileName = this.videoTemp.name;
  let movie: any;
    const extensionIndex = fileName.lastIndexOf('.');
    const uniqueFileName =
      extensionIndex !== -1
        ? `${fileName.slice(0, extensionIndex)}-${uniqueId}${fileName.slice(extensionIndex)}`
        : `${fileName}-${uniqueId}`;

    const url = `https://${this.HOSTNAME}/${this.STORAGE_ZONE_NAME}/movie-lust-list/${uniqueFileName}`;

    const formData = new FormData();
    formData.append('file', this.videoTemp);

    this.uploadView = true;
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('AccessKey', this.ACCESS_KEY);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        this.progressValue = Math.round((event.loaded / event.total) * 100);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 201) {
        this.uploadVideoButton = true;

        const publicUrl = `https://lust-list.b-cdn.net/movie-lust-list/${uniqueFileName}`;

        // Crear objeto videoCloud
        const videoCloud = {
          title: fileName, // Título original del archivo
          videoUrl: publicUrl, // URL pública del archivo
          // eslint-disable-next-line @typescript-eslint/naming-convention
          public_id: uniqueFileName, // Nombre único generado para el archivo
          exclusiveContent: false, // Valor predeterminado
          sizeMb: this.videoTemp.size / 1000000, // Tamaño del archivo en MB
        };

        this.movieSaved.emit(videoCloud);

        this.loading.dismiss();
        this.videoPreview.emit(this.videoTemp);
        this.uploadView = false;
        this.progressValue = 100;
      } else {
        console.error('Error al subir archivo:', xhr.responseText);
        this.uploadView = false;
        this.uploadVideoButton = true;
        this.loading.dismiss();
      }
    };

    xhr.onerror = () => {
      console.error('Error en la solicitud de subida');
      this.uploadView = false;
    };

    xhr.send(this.videoTemp);
}

  deleteVideo() {
    this.videoTemp = null;
    this.recordVideoElement.src = '';
    this.isPlayVideo = false;
  }

  clickInputGallery() {
    this.toastService.presentToast('NOTIFICATION.ONLY_FORMAT_MP4', 3000);
    this.inputGallery.nativeElement.click();
  }

  getVideoFromGallery(event: any) {
    this.recordVideoElement = this.recordVideoElementRef.nativeElement;
    if (event.target.files.length > 0) {
      const inputElement = event.target as HTMLInputElement;
      const file = event.target.files[0];
      const fileSizeInBytes = file.size;
      const sliceSize = 6000000;
      this.sectionsToUpload = Math.ceil(fileSizeInBytes / sliceSize);

      if (fileSizeInBytes < 20000000) {
        this.isShortVideo = true;
      }

      const maxSizeInBytes = 1000 * 1024 * 1024;
      if (fileSizeInBytes > maxSizeInBytes) {
        this.toastService.presentToast('PUBLICATION.TOAS_MAX_HEIGHT_LAST_VIDEO', 3000);
        inputElement.value = '';
      } else {
        this.recordVideoElement.src = URL.createObjectURL(file);
        this.recordVideoElement.onloadedmetadata = () => {
          const durationInSeconds = this.recordVideoElement.duration;
          const minDurationInSeconds = 60;

          if (durationInSeconds < minDurationInSeconds) {
            this.toastService.presentToast('PUBLICATION.TOAS_MIN_LARGE_VIDEO', 3000);

            inputElement.value = '';
          } else {
            this.isPlayVideo = true;
            this.videoTemp = file;
          }
        };

      }

    }
  }

  uploadShortVideo(videoTemp) {

    const formData = new FormData();
    formData.append('file', videoTemp);

    this.publicationService.saveVideo(formData).subscribe((res) => {
      this.movieSaved.emit(res);
      this.videoPreview.emit(videoTemp);
      this.toastService.presentToast('PUBLICATION.VIDEO_SAVED_SUCCESSFULLY', 2000);
    });
    this.deleteVideo();
    this.uploadView = false;
  }

  uploadVideo(videoData, video) {

    this.moviesService.uploadMovie(videoData).subscribe((res) => {
      this.movieSaved.emit(res);
      this.videoPreview.emit(video);
      this.toastService.presentToast('PUBLICATION.VIDEO_SAVED_SUCCESSFULLY', 2000);
    });
    this.deleteVideo();
    this.uploadView = false;

  }

  showLoading() {
     const  options = {
      message: this.translateService.instant('NOTIFICATION.LOADING'),
      cssClass: 'custom-loading',
    };

    this.loadingCtrl.create(options).then((res: HTMLIonLoadingElement) => {
      this.loading = res;
      this.loading.present();
    });
  }

  updateProgress() {
    const fractionAdd = Math.ceil(100 / this.sectionsToUpload);
    this.progressValue = this.progressValue + fractionAdd;

    if (this.progressValue > 100) {
      this.progressValue = 100;
    }

    if (this.progressValue === 100) {
      setTimeout(() => {
        this.uploadView = false;
        this.toastService.presentToast('PUBLICATION.VIDEO_SAVED_SERVER', 2000);
      }, 2000);
    }

  }


}
