import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,ViewChild } from '@angular/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { PublicationService } from '../../services/publication/publication.service';
import { SocketService } from '../../services/socket/socket.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout/layout.service';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router} from '@angular/router';
import { UsersService } from '../../services/users/users.service';
import { ToastService } from '../../services/toast/toast.service';
import { Subscription } from 'rxjs';
import { IonTextarea } from '@ionic/angular';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() imagePublication: any = {};
  @Output() windowComment = new EventEmitter<any>();
@ViewChild('comment', { static: true }) commentTextarea: IonTextarea;
  listComments: any = [];
  myId = '';
  myUser: any = null;
  incognito = false;
  isComments = false;
  publicationId = '';

  commetInput = new FormControl('');
  totalComments: number = null;

  newComment = '';
  commentIdEdit = '';

  moreOptionOpen = false;
  indexComment: null | number = null;

  subscriptionPlatform = false;
  subscriptionSubscriptionPlatform: Subscription;

  constructor(
    private publicationService: PublicationService,
    private authService: AuthServiceService,
    private socketService: SocketService,
    private translateService: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private userService: UsersService,
    private toastService: ToastService,
  ) { }
  ngOnDestroy(): void {
    if (this.subscriptionSubscriptionPlatform) {
      this.subscriptionSubscriptionPlatform.unsubscribe();
    }
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.closeComments();
      }
    });

    this.subscriptionSubscriptionPlatform = this.layoutService.getSubscriptionPlatform().subscribe((val) => {
      this.subscriptionPlatform = val;
    });
    const user = this.authService.getLocalStorage('user');
    const userParsed = JSON.parse(user);
    this.myUser = userParsed;
    this.myId = userParsed._id;
    this.layoutService.getShowModalCommentFrame().subscribe(val => {this.isComments = val;});
    if (this.isComments) {
      this.layoutService.getDataCommentFrame().subscribe((data: any) => {
        if (data._id) {
          data.id = data._id;
          delete data._id;
        }
        //REVISAR POR QUE HICIERON ESO //
        // if (!this.myUser?.imageProfile?.imageUrl) {
        //   //this.router.navigateByUrl('/settings');
        //   this.closeComments();
        // }
        this.imagePublication = data;
        this.getComments(true);
      });
    }
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imagePublication.currentValue) {
      this.getComments(true);
    }
  }


  getComments(updateCuantityComments) {
    this.publicationService.getCommentsPublication(this.imagePublication.id).subscribe((publication: any) => {
      publication.comments.map(res => {
        if (!res.userId.imageProfile) {
          res.userId.imageProfile = {imageUrl: '/assets/images/profile.png'};
        }
        if (res.userId.incognito) {
          res.userId.imageProfile = {imageUrl: '/assets/icon/Icons-Lust - Icongnit Negative.svg'};
          res.userId.userName = this.translateService.instant('PROFILE.UNKNOWN');
        }

      });
      this.listComments = publication.comments;
      if (updateCuantityComments) {
        this.totalComments = this.listComments.length;
      }
    });
  }


 capitalizarPrimeraLetra(event ) {
    const texto = event.detail.value as string;

    if (texto.length > 0) {
      // Capitalizamos la primera letra y conservamos el resto del texto
      const textoCapitalizado = texto.charAt(0).toUpperCase() + texto.slice(1);

      // Actualizamos el valor del textarea con la primera letra en mayúscula
      this.commentTextarea.value = textoCapitalizado;
    }
  }
  navigteTo(id) {
    let typeUser = 0;
    this.userService.getDataProfile(id).subscribe((val: any) => {
    typeUser = val.dataUser.type.type;
         if (id === this.myId && typeUser === 1) {
           this.router.navigate(['/profile', id]);
           this.layoutService.setShowModalCommentFrame(false);
    }
    if (id !== this.myId && typeUser === 1) {
      this.router.navigate(['/profile', id]);
      this.layoutService.setShowModalCommentFrame(false);
    }

      if (id === this.myId && typeUser === 2) {
        this.router.navigate(['/settings']);
        this.layoutService.setShowModalCommentFrame(false);
      }
      if (id !== this.myId && typeUser === 2) {
      this.layoutService.setShowModalClientInfo(true);
      this.layoutService.setShowModalDataClientInfo(id);

      }

     });
  }

  closeComments() {
    this.windowComment.emit(false);
    this.layoutService.setShowModalCommentFrame(false, this.totalComments);
  }

  createComments(comments: any[]) {
    if (comments) {
      comments.forEach((comment) => {
        const newComment = {
          img: this.imagePublication.userId.imageProfile,
          name: this.imagePublication.userId.username,
          comment: comment.comment,
        };
        this.listComments.push(newComment);
      });
    }
  }

  getUserType() {
    return this.authService.getLocalStorage('userType');
  }

  publish(body, comment) {
    // if (!this.subscriptionPlatform && this.getUserType() === '2') {
    //     this.closeComments();
    //     const data = {
    //       type: 'client'
    //     };
    //     this.layoutService.setDataModalPaymentSubsPlataform(data);
    //     this.layoutService.setShowModalPaymentSubsPlataform(true);

    //     return;
    // }


    const commentObject = {comment: comment.value};
    this.publicationService
      .addCommentPublication(commentObject, this.imagePublication.id)
      .subscribe((res: any) => {
        this.totalComments = res.comments.length;
        this.layoutService.setShowModalCommentFrame(true, this.totalComments);

        comment.value = '';
        this.getComments(false);
        if (this.imagePublication.userId._id !== this.myId) {
          const notification = {
            userReceiver: this.imagePublication.userId._id,
            userSender: this.myId,
            type: 'comment',
            message: 'NOTIFICATION.COMMENT_YOUR_POST',
            contextId: this.imagePublication.id,
            linkRedirect: '/profile/'+this.imagePublication.userId._id
          };
          this.socketService.sendNotification(notification);
        }
      });
    //body.scrollToBottom(500);
  }

  deleteComment(idComment) {
    this.publicationService
      .deleteCommentPublication(this.imagePublication.id, idComment)
      .subscribe((res: any) => {
        this.totalComments = res.comments.length;
        this.layoutService.setShowModalCommentFrame(true, this.totalComments);

        this.closeModal();
        this.getComments(false);
      });
  }

  editComment(idComment) {
    if (this.newComment) {
      this.publicationService
        .editComment(idComment, this.newComment)
        .subscribe((res: any) => {
          this.newComment = '';
          this.commentIdEdit = '';
          this.getComments(false);
        });
    }
  }

  selectCommentForEdit(item) {
    this.commentIdEdit = item._id;
    this.newComment = item.comment;
    this.moreOptionOpen = false;
  }

  onOpenModal(i) {
    this.commentIdEdit = '';
    this.indexComment = i;
    this.moreOptionOpen = true;
  }

  closeModal() {
    this.indexComment = null;
    this.moreOptionOpen = false;
  }

}
