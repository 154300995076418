// eslint-disable-next-line max-len
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';

import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { PublicationService } from '../../services/publication/publication.service';
import { TransactionsService } from '../../services/transactions/transactions.service';
import { UsersService } from '../../services/users/users.service';

import SwiperCore, { Navigation, Pagination } from 'swiper';
import { LayoutService } from '../../services/layout/layout.service';
import { AccountsService } from '../../services/accounts/accounts.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast/toast.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { SocketService } from '../../services/socket/socket.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileService } from '../../services/file/file.service';



SwiperCore.use([Pagination,Navigation]);

@Component({
  selector: 'app-gallery-uniform',
  templateUrl: './gallery-uniform.component.html',
  styleUrls: ['./gallery-uniform.component.scss'],
})
export class GalleryUniformComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('gallery') gallery: ElementRef;
  @ViewChildren('snapBox') snapBoxes!: QueryList<ElementRef>;
  @ViewChildren('snapBox') snapBoxElements!: QueryList<ElementRef>;
  @Input() images = [];
  @Output() imageSelected = new EventEmitter<any>();
  @Output() windowComment = new EventEmitter<any>();
  @Output() requestMoreData = new EventEmitter<void>();
  @Output() toggleStateContainer = new EventEmitter<boolean>();

  observer: IntersectionObserver;
  intersectionObserver: IntersectionObserver;
  activeIndex = 0;
  username = '';
  isModalOpen = false;
  isModalReport = false;
  typeReport ='report-publication';
  myId = '';
  userId = '';
  priceForSubscription = 0;
  sharedSubscription = 0;
  loading: HTMLIonLoadingElement;
  messageSubscription = {};

  moreOptionOpen = false;
  closeOption = null;
  imgSelected: any = undefined;

  followings = [];
  subscribers = [];
  userType = false;

  indexImg: null | number = null;
  reasonReportData ='';

  myCash = 0;
  sharePublication = false;
  copyActive = false;
  linkRedirect: string;
  titleModal = '';
  authorId = '';
  videoPlay = true;
  showModalUnsucribe = false;
  currentVideoIndex: any;
  idCommentsPublication: any = null;

  subscriptionViewAllStatusUser: Subscription;
  subscriptionListenerNewStates: Subscription;
  usersAllViewStates = [];
  fullScreen = false;


  subscriptionFollowers: Subscription;
  subscriptionSubscribers: Subscription;

  isDeviceIOS = false;

  activeUsers: any = [];
  visibilityStatus: boolean[] = [];
  elementVisibilityStatus: boolean[] = [];
  activeUserSubscription: Subscription;

  aspectoRatio = 16 / 9;

  currentSlideIndex = 0;
  showStateContainer= false;
  private env = environment;

  constructor(
    private usersService: UsersService,
    private authService: AuthServiceService,
    private transactionService: TransactionsService,
    private publicationService: PublicationService,
    private layoutService: LayoutService,
    private accountsService: AccountsService,
    private translateService: TranslateService,
    private loadingCtrl: LoadingController,
    private router: Router,
    private toastService: ToastService,
    private socketService: SocketService,
    private sanitizer: DomSanitizer,
    private fileService: FileService,
    private renderer: Renderer2,
    private elRef: ElementRef,
     private cdr: ChangeDetectorRef

  ) {
    this.intersectionObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      const elementIndex = this.snapBoxElements.toArray().findIndex(
        (element) => element.nativeElement === entry.target
      );

      const isElementVisible = entry.isIntersecting;

      // Si la visibilidad del índice cambia, actualizamos `currentVideoIndex`
      if (this.elementVisibilityStatus[elementIndex] !== isElementVisible) {
        this.elementVisibilityStatus[elementIndex] = isElementVisible;

        if (isElementVisible) {
          this.currentVideoIndex = elementIndex;
          this.showStateContainer = elementIndex === 0;

          this.toggleStateContainer.emit(this.showStateContainer);

          // // Logs para verificar el comportamiento
          // console.log(`Imagen en el índice ${elementIndex} es visible.`);
          // console.log(`Contenedor de estados ${this.showStateContainer ? 'visible' : 'oculto'}.`);
        } else if (this.currentVideoIndex === elementIndex) {
          this.currentVideoIndex = null;
          // console.log(`Imagen en el índice ${elementIndex} ya no es visible.`);
        }
      }
    });
  },
  { threshold: 0.5 }
);
  }



  ngOnDestroy(): void {
    if (this.intersectionObserver ) {
       this.intersectionObserver.disconnect();
    }
     if (this.observer) {
      this.observer.disconnect();
    }
    if (this.subscriptionViewAllStatusUser) {
      this.subscriptionViewAllStatusUser.unsubscribe();
    }
    if (this.subscriptionListenerNewStates) {
      this.subscriptionListenerNewStates.unsubscribe();
    }
    if (this.subscriptionFollowers) {
      this.subscriptionFollowers.unsubscribe();
    }
    if (this.subscriptionSubscribers) {
      this.subscriptionSubscribers.unsubscribe();
    }
    if (this.activeUserSubscription) {
      this.activeUserSubscription.unsubscribe();
    }
    if (this.gallery) {
      this.renderer.listen(this.gallery.nativeElement, 'scroll', null);
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.images.currentValue) {
      this.images.map(img => img.quantityComments = img.comments.length);
    }
     if (changes.images && this.gallery) {
      this.scrollToImage(this.activeIndex);
    }
  }

  getWidthGallery(): number {
    return this.gallery?.nativeElement?.offsetWidth ? this.gallery?.nativeElement?.offsetWidth : 300;
  }


   setupObservers() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = this.snapBoxes.toArray().findIndex(
            (snapBox) => snapBox.nativeElement === entry.target
          );

          const isVisible = entry.isIntersecting;
          if (this.visibilityStatus[index] !== isVisible) {
            this.visibilityStatus[index] = isVisible;
          }
        });
      },
      { threshold: 0.5 }
    );

    this.snapBoxes.forEach((snapBox, index) => {
      this.visibilityStatus[index] = false;
      observer.observe(snapBox.nativeElement);
    });
  }

  ngOnInit() {
       this.initIntersectionObserver();
    this.isDeviceIOS = this.authService.isDeviceIOS;
    const user = this.authService.getLocalStorage('user');
    const userParsed = JSON.parse(user);
    this.myId = userParsed._id;
    if (userParsed.contentManagerId) {
      this.myId = userParsed.contentManagerId;
    }

    this.subscriptionFollowers =this.usersService.getMyFollowing().subscribe((res: any) => {
      this.followings = res;
    });
    this.subscriptionSubscribers = this.usersService.getSubscriptions().subscribe((res: any) => {
      this.subscribers = res.map(subs => {
        if (subs.userSellerId[0]?._id) {
          return subs.userSellerId[0]._id;
        } else {
          return subs.userSellerId;
        }
      });
    });
    this.accountsService.getMyCash().subscribe(
      (res: any) => {
        this.myCash = res.cash;
      },
      err => console.log(err)
    );

    this.layoutService
      .getShowModalCommentFrame()
      .subscribe((val) => {
        if (val === false && this.idCommentsPublication) {
          const index = this.images.findIndex(img => img.id === this.idCommentsPublication);
          if (this.layoutService.quantityComments !== this.images[index].quantityComments) {
            this.images[index].quantityComments = this.layoutService.quantityComments;
          }
          this.idCommentsPublication = null;
        }
      });



    this.subscriptionViewAllStatusUser = this.layoutService.getNewUserViewAllStates().subscribe((usersAllViewStates: string[]) => {
      this.usersAllViewStates = usersAllViewStates;
    });

    this.subscriptionListenerNewStates = this.socketService.listenerNewStatesMySubscriptions().subscribe((state: any) => {
      const publicationsByUser = this.images.filter(pub => pub.authorId
        === state.userId);

      for (const pub of publicationsByUser) {
        const indexPub = this.images.findIndex(img => img.id === pub.id);
        this.images[indexPub].states.push(state);
      }
    });

    this.activeUserSubscription = this.usersService.getUsersActive().subscribe(user => {
      this.activeUsers = user;
    });
  }

  ngAfterViewInit(): void {
    this.observeSnapBoxElements();
     this.cdr.detectChanges(); // Forzar la detección de cambios

    this.snapBoxElements.changes.subscribe(() => {
      this.observeSnapBoxElements();
    });

 this.observeLastBox();
    this.snapBoxes.changes.subscribe(() => {
      this.observeLastBox();
    });
  }
   observeSnapBoxElements() {
     this.snapBoxElements.forEach((element, index) => {
      if (!this.elementVisibilityStatus[index]) {
        this.elementVisibilityStatus[index] = false;
        this.intersectionObserver.observe(element.nativeElement);
      }
    });
  }

  observeLastBox() {
     if (this.snapBoxes.length > 0) {
      const boxesArray = this.snapBoxes.toArray();
      const lastBox = boxesArray[boxesArray.length - 1].nativeElement;
      this.observer.disconnect();
      this.observer.observe(lastBox);
    }
   }


  initScrollSnapping() {
    this.renderer.listen(this.gallery.nativeElement, 'scroll', () => {
      const container = this.gallery.nativeElement;
      const scrollTop = container.scrollTop;
      const containerHeight = container.offsetHeight;
      const newIndex = Math.round(scrollTop / containerHeight);
      this.activeIndex = Math.max(0, Math.min(newIndex, this.images.length - 1));
      this.scrollToImage(this.activeIndex);
    });
  }

   scrollToImage(index: number) {
    const container = this.gallery.nativeElement;
    const containerHeight = container.offsetHeight;

    const targetScrollTop = index * containerHeight;

    container.scrollTo({
      top: targetScrollTop,
      behavior: 'smooth',
    });
  }
  playVideo() {
  this.videoPlay = !this.videoPlay;
}
  isActive(id: string) {
    return this.activeUsers.find(userActive => userActive.user === id)?.active;
  }

  snapIndexChange(event: any) {
    this.currentVideoIndex = event[0].snapIndex;
    const activeIndex = event[0].snapIndex;
    this.currentSlideIndex = event.snapIndex;
    if (activeIndex >= 0) {
      const activeSlide: HTMLElement = event[0].slides[activeIndex];
      const assetElement = activeSlide.querySelector('audio') ? activeSlide.querySelector('audio') : '';

      if (assetElement) {
        setTimeout(() => {
          assetElement.removeAttribute('hidden');
        }, 500);
      }
    }
  }

  evaluateStates(publication) {
    const states = publication?.states;
    if (!states) {
      return null;
    }

    let state = null;
    if (states?.length === 0 || publication.authorId === this.myId){
      state = 'noState';
    } else if (states.every((state) => state.seen.includes(this.myId)) || this.usersAllViewStates.includes(publication.authorId)) {
      state ='allView';
    }
    return state;
  }

  transformVideo(video) {

    return this.fileService.transformUrlVideo(video.videoUrl);
  }

  playFullScreenIos(url) {

    const lastVideo = document.getElementById('last-video');

    if (lastVideo) {
      document.body.removeChild(lastVideo);
    }
    const video: any = document.createElement('video');
    video.src = this.fileService.transformUrlVideo(url);
    video.type = 'video/mp4';
    video.controlsList = 'nodownload';
    video.controls = true;
    video.id='last-video';
    document.body.appendChild(video);

    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      video.msRequestFullscreen();
    }
    if (this.isDeviceIOS) {
     video.play();
    }
    video.onfullscreenchange = (event) => {

      if (this.fullScreen) {
        this.fullScreen = false;
        document.body.removeChild(video);
      } else {
        this.fullScreen = true;
      }

     };
  }

   initIntersectionObserver() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.checkIndex();
          }
        });
      },
      {
        root: null,
        threshold: 0.5,
      }
    );
    const snapBoxes = this.elRef.nativeElement.querySelectorAll('.snap-box');
    snapBoxes.forEach((box: HTMLElement) => this.observer.observe(box));
  }



  checkIndex() {
  this.requestMoreData.emit();
  }

  openModalWish(userAuthorId) {
    // const wishData = {
    //   userId: userAuthorId._id ? userAuthorId._id : userAuthorId
    // };
    // this.layoutService.setDataWishComponentNewWish(wishData);
    // this.layoutService.setShowComponentNewWish(true);
        this.layoutService.setShowModalWarning(true);
    this.layoutService.setRedirectModalWarning(true);
  }

  openModalOffert(publication) {
    const offerData = {
      userClientId: this.myId,
      userSellerId: publication?.userId,
      publication,
      amount: 0,
      comments: []
    };
    this.layoutService.setDataNewOffer(offerData);
    this.layoutService.setShowNewOffer(true);
  }

navigateTo(data) {
    if (data.userType === 1) {
        this.router.navigate(['/profile', data.userId._id]);
        this.usersService.registerViewProfileByPublication(this.myId, data.userId._id, data.id).subscribe();
        return; // Detenemos la ejecución aquí
    }

    if (data.userType === 2) {
        if (data.userId._id === this.myId) {
            this.router.navigate(['/settings']);
            return; // Detenemos la ejecución aquí
        } else {
            this.toastService.presentToast('GENERAL.PROFILE_NOT_PUBLIC', 2000);
            return; // Detenemos la ejecución aquí
        }
    }
}


unfollow(publication) {
  const userSellerId = publication.userId._id;
  // Actualización optimista de `followings`
  this.followings = this.followings.filter(id => id !== userSellerId);

  this.usersService.unfollow({ userSellerId }).subscribe(
    () => {
    },
    (err) => {
      console.error(err);
      // Revertir la eliminación si hay un error
      this.followings.push(userSellerId);
    }
  );
}


follow(publication) {
  const userSellerId = publication.userId._id;
  // Actualización optimista de `followings`
  this.followings.push(userSellerId);

  this.usersService.follow({ userSellerId }).subscribe(
    () => {
      this.usersService.registerFollowedByPublication(this.myId, publication.userId._id, publication.id).subscribe();
    },
    (err) => {
      console.error(err);
      // Revertir si hay un error
      this.followings = this.followings.filter(id => id !== userSellerId);
    }
  );
}


   getUserType() {
    return this.authService.getLocalStorage('userType');
  }

  getImageAudio(publication){

    let image = publication.userId
      .imageProfile.imageUrl;

    if (publication.images.length > 0) {
      image = publication.images[0].imageUrl;
    }

    if (publication.videos.length > 0) {
      image = publication.videos[0].videoUrl.replace(/\.[^/.]+$/, '.webp');
    }

    return image;
  }
  getImageVideo(video) {
    return video.videoUrl.replace('webm', 'webp').replace('mp4', 'webp').replace('mov', 'webp');
  }

  /**
   * Obtiene los datos de la imagen seleccionada
   *
   * @param image El objeto de la imagen
   */
  selectImage(image: any) {
    this.imageSelected.emit(image);
  }

  /**
   * Verifica si la imagen que recibe por input es un usuario o una publicacion
   *
   * @returns true or false si la imagen tiene una propiedad "name"
   */
  hasNameImage() {
    return this.images[0]?.hasOwnProperty('name');
  }

  /**
   * Envia por output la imagen seleccionada
   *
   * @param image La imagen donde va a cambiar el estado de like
   */
  changeLikesStatus(image) {
    const action = image.publicationLikes.includes(this.myId) ? 'dislike' : 'like';
  this.publicationService[action](image.id).subscribe((res: any) => {
    image.likes = res.likes;
    image.publicationLikes = res.publicationLikes.usersId;

    const index = this.images.findIndex(img => img.id === image.id);
    if (index !== -1) {
      this.images[index].likes = res.likes;
      this.images[index].publicationLikes = res.publicationLikes.usersId;
    }
  });
}


  /**
   * Formatea la imagen en caso de que el usuario no tenga imagen
   *
   * @param image Los datos de la imagen
   * @returns La url de la imagen
   */
  formatImageProfile(image) {
    return !image?.imageProfile?.imageUrl
      ? '../../../../assets/icon/usuario-30.png'
      : image.imageProfile.imageUrl;
  }

  /**
   * Abre el modal para hacer comentarios
   *
   * @param publication La publicación donde se colocará el comentario
   */
  openModalComments(publication) {
    this.layoutService.setShowModalCommentFrame(true);
    this.layoutService.setDataCommentFrame(publication);
    this.idCommentsPublication = publication.id;
  }

  /**
   * Cierra el modal
   *
   * @param status Indica si el modal se cierra o se abre
   */
  // closeComments(status) {
  //   this.openComments = status;
  //   this.layoutService.setShowComponentComment(false);
  // }

  onClickButtonSubscription(image: any) {
 this.showLoading();
    this.userId = image.userId._id;
    this.usersService
      .getQuotizationSubscription(this.userId)
      .subscribe((res: any) => {

        this.usersService.registerSubscribedByPublication(this.myId, this.userId, image.id).subscribe();

        this.priceForSubscription = res.subscriptionPrice;
        if (this.priceForSubscription >= 0) {
           this.loading.dismiss();
           this.isModalOpen = true;
        }
        this.isModalReport = false;
        this.sharedSubscription = res.share;
        this.titleModal = 'BUTTON.SUBSCRIBE';
        this.messageSubscription = {
          title: 'Subscribirte',
          message:'MODAL.SUSCRIBE'
        };
      });
  }

  openModalMetrics(publication) {
    this.publicationService.getMetricsPublication(publication.id).subscribe((val: any) => {
      const data = {
        from: 'gallery-uniform',
        data: val
      };
      this.layoutService.setShowModalMetrics(true);
      this.layoutService.setDataModalMetrics(data);
    });
  }

  subscribe() {
    this.showLoading();

    const transaction = {
      userClientId: this.myId,
      userSellerId: this.userId,
      aprovedClient: true,
      aprovedSeller: true,
      amount: this.priceForSubscription,
      type: 1,
    };

    this.isModalOpen = false;
     this.transactionService
       .registerTransactionForUserIds(transaction)
      .subscribe(
       (res: any) => {

           const subscription = {
            userClientId: this.myId,
            userSellerId: this.userId,
            transaction: res._id,
            price: this.priceForSubscription,
             createdDate: 'Date',
             expiredDate: 'Date',
           status: 1,
          };
           this.usersService
              .subscribeUser(subscription)
              .subscribe(() => {
               this.layoutService.setUpdateStatus();
               this.loading.dismiss();
             },
               (err) => {
                 this.loading.dismiss();
                });

        },
         (error) => {
       console.error(error);
        }

       );
  }
   showLoading() {
    const options = {
      message: this.translateService.instant('NOTIFICATION.LOADING'),
      cssClass: 'custom-loading',
    };
    this.loadingCtrl.create(options).then((res: HTMLIonLoadingElement) => {
      this.loading = res;
      this.loading.present();
    });
  }


  onClickUnsubscribe(userId) {
    this.authorId = userId;
    this.isModalOpen = true;
    this.isModalReport = true;
    this.typeReport = 'unsuscribe';
  }

  unSuscribe() {
     this.showLoading();
      this.usersService.unsubscribe(this.authorId).subscribe(() => {
      this.loading.dismiss();
      this.isModalOpen = false;
      this.showModalUnsucribe = true;
    },
      (err) => {
        this.loading.dismiss();
      });
  }


  externalPayment() {
    const payload = {
      userId : this.userId,
      contextId: this.userId,
      title: 'suscription',
      amount: this.priceForSubscription,
      typePayment: 'payment'
    };
    this.layoutService.setShowModalPaymentMethods(true);
    this.layoutService.setDataModalPaymentMethods(payload);

  }



  onOpenModal(i: number) {
    this.indexImg = i;
    this.imgSelected = this.images[i];
    this.moreOptionOpen = true;
    this.copyActive = false;
  }


  closeModal(i: number) {
    if (i) {
      this.indexImg = i;
    }
    this.moreOptionOpen = false;
  }

  calculateAgePublication(date: string) { }

  showModalCopy(i: any) {
    this.imgSelected = this.images[i];
    this.sharePublication = true;
    this.linkRedirect = this.env.urlFront + 'preview/' + this.imgSelected.id;
  }

  copySharedProfile(value) {
    this.copyActive = true;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
     this.toastService.presentToast('NOTIFICATION.COPIED_CLIPBOARD', 2000);
  }

  closeSharedPublicationModal() {
    this.sharePublication = false;
    this.moreOptionOpen = false;
  }

  reasonReport(data) {
    this.reasonReportData = data;
  }
  confirmModal() {

    if (!this.isModalReport) {
      this.subscribe();
    } else {
      switch (this.typeReport) {
        case 'report-profile':
          this.reportProfile();
          break;
        case 'report-publication':
          this.reportPublication();
          break;
        case 'hidden-publication':
          this.hiddenPublication();
          break;
        case 'unsuscribe':
          this.unSuscribe();
          break;
        default:
          break;
      }
    }
  }

  getSafeLink(url: any): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  //reports
  openModalReportPublication() {
    this.isModalOpen = true;
    this.isModalReport = true;
    this.typeReport = 'report-publication';
    this.moreOptionOpen = false;
  }

  openModalReportProfile() {
    this.isModalOpen = true;
    this.isModalReport = true;
    this.typeReport = 'report-profile';
    this.moreOptionOpen = false;
  }

  openModalHiddenPublication() {
    this.isModalOpen = true;
    this.isModalReport = true;
    this.typeReport = 'hidden-publication';
    this.moreOptionOpen = false;
  }

  hiddenPublication() {
    this.usersService.excludePublication(this.imgSelected.id).subscribe((res) => {
      this.images = this.images.filter(image => image.id !== this.imgSelected.id);
      this.toastService.presentToast('NOTIFICATION.HIDDEN_PUBLICATION', 2000);
      this.closeModal(this.indexImg);
      this.moreOptionOpen = false;
      this.isModalOpen = false;
      this.isModalReport = false;

    });
  }

  reportPublication() {
    this.usersService.report(this.imgSelected.authorId,this.reasonReportData, this.imgSelected.id).subscribe((res) => {

      this.toastService.presentToast('NOTIFICATION.REPORT_PUBLICATION', 2000);
      this.closeModal(this.indexImg);
      this.moreOptionOpen = false;
      this.isModalOpen = false;
      setTimeout(() => {
        this.openModalHiddenPublication();
      }, 500);
    });
  }

  reportProfile() {
    this.usersService.report(this.imgSelected.authorId,this.reasonReportData).subscribe((res) => {
      this.toastService.presentToast('NOTIFICATION.REPORT_PROFILE', 2000);
      this.closeModal(this.indexImg);
      this.moreOptionOpen = false;
      this.isModalOpen = false;
      setTimeout(() => {
        this.openModalHiddenPublication();
      }, 500);
    });
  }

  openStateModal(states) {
    this.layoutService.setShowStateModal(states);
  }
}
